exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anlass-muttertag-js": () => import("./../../../src/pages/Anlass/Muttertag.js" /* webpackChunkName: "component---src-pages-anlass-muttertag-js" */),
  "component---src-pages-anlass-vatertag-js": () => import("./../../../src/pages/Anlass/Vatertag.js" /* webpackChunkName: "component---src-pages-anlass-vatertag-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nutzungsbedingungen-js": () => import("./../../../src/pages/nutzungsbedingungen.js" /* webpackChunkName: "component---src-pages-nutzungsbedingungen-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-service-digitales-mixtape-js": () => import("./../../../src/pages/Service/Digitales-Mixtape.js" /* webpackChunkName: "component---src-pages-service-digitales-mixtape-js" */)
}

